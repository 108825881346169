<template>
  <v-app :class="appClass">

     <app-header></app-header>
      <app-menu></app-menu>

      <v-main app class="pt-16 pb-16">
        <router-view :key="$route.path"></router-view>
      </v-main>

    <v-snackbar
        v-model="$snackbar.isOpen"
        :timeout="$snackbar.timeout"
    >
      {{ $snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="$snackbar.hide()"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>


    <auth-dialog v-if="$auth.isDialogOpen"></auth-dialog>

  </v-app>
</template>

<script>

  import AppHeader from './components/AppHeader.vue'
  import AppMenu from './components/AppMenu.vue'
  import AuthDialog from "@/components/AuthDialog";
  import favicon from './assets/logo.svg'

export default {
  name: 'App',

  components: {
    AuthDialog,
    AppHeader,
    AppMenu,
  },

  data: () => ({
  }),

  computed : {
    appClass () {
      const classes = [];
      if (this.$menu.isOpen) {
        classes.push('menu-opened')
      }
      else {
        classes.push('menu-closed')
      }
      if (!this.$auth.loggedIn) {
        classes.push('blur')
      }
      return classes.join(' ')
    },
  },

  created() {
    window.document.title = 'Videoplayer Admin'

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;

    this.$api.get(`/auth/get`)
        .then((resp) => {
          this.$auth.user = resp.data;
        })

  }
};
</script>
