<template>
	<v-container fluid>
		<v-row class="pa-4">
      <v-btn  @click="save" color="primary">Save</v-btn>
			<v-spacer></v-spacer>
			<v-btn small to="/device" >Back to list</v-btn>
		</v-row>

		<v-row>

			<v-col>

        <v-row>
          <v-col cols="5">
            <v-form v-model="valid" ref="form">

              <v-card>
                <v-card-title>Basic datas</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field label="Device name" :rules="[rules.required]" outlined v-model="item.name"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="$auth.isAdmin">
                      <v-select :items="groups" :rules="[rules.required]" item-text="name" item-value="id" label="User Group" outlined v-model="item.user_group_id" ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>

          <v-col cols="7">
            <v-card>
              <v-card-title>Playlists</v-card-title>
              <v-card-text>
                <v-select :items="playlists" :rules="[rules.required]" item-text="name" item-value="id" label="Default playlist" outlined v-model="item.playlist_id" ></v-select>
              </v-card-text>

              <v-card-text>
                <v-data-table
                :headers="timingHeaders"
                :items="timings">

                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Timed lists</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn small color="primary" @click="editedTiming = {}">Add new timing</v-btn>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.from`]="{ item }">
                    {{ item.start_date }}
                  </template>

                  <template v-slot:[`item.to`]="{ item }">
                    {{ item.end_date }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="editedTiming = item" icon class="mr-2">
                      <v-icon
                          small
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteTiming = item">
                      <v-icon
                          small
                      >
                        mdi-delete
                      </v-icon></v-btn>
                  </template>

                </v-data-table>


              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-col>






      <v-col md="4" lg="3">
				<v-card class="mb-4">
					<v-card-title><v-icon :color="statusIconColor">{{ statusIcon }}</v-icon>{{ statusText }}</v-card-title>
					<v-card-text>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Last sync time</v-list-item-subtitle>
                <v-list-item-title>{{ lastSync }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

					</v-card-text>
				</v-card>


				<v-card>
					<v-card-title>Control</v-card-title>
					<v-card-actions>
						<v-btn small @click="doRestart">Reboot</v-btn>
						<v-spacer></v-spacer>
						<v-btn small @click="doShutdown">Shutdown</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>


		</v-row>


		<v-row>
			<v-col>
				<v-card>
					<v-card-title>Cpu usage</v-card-title>
					<v-card-text class="pb-14">
						<div class="cpu-usage" ref="cpuChart"></div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-card>
					<v-card-title>Memory usage</v-card-title>
					<v-card-text class="pb-14">
						<div class="memory-usage" ref="memoryChart"></div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-card>
					<v-card-title>Cpu temperature</v-card-title>
					<v-card-text class="pb-14">
						<div class="cpu-temp" ref="tempChart"></div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>



  <timing-dialog v-model="editedTiming" :playlists="playlists" @saved="addTime"></timing-dialog>

    <v-dialog v-if="deleteTiming" v-model="deleteTiming" width="400">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteTiming=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDeleteTiming">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


	</v-container>
</template>
<script>

	import Chartist from 'chartist'
	import moment from 'moment'
  import TimingDialog from "@/components/TimingDialog";


	export default {

		components: {
      TimingDialog
		},

		data() {
			return {
				item : {
					name: ''
				},
        editedTiming: null,
        loadTimingSave: false,
        valid:false,
				headers:[],
				cpuUsages:[],
				cpuTemperatures:[],
				memoryUsages:[],
				playlists:[],
        poiCategory:null,
        groups: [],
        timingHeaders: [
          {text: 'playlist', value: 'playlist.name'},
          {text: 'from', value: 'from'},
          {text: 'to', value: 'to'},
          {text: '', value: 'actions', width: 120},
        ],
        timings: [],
        rules: {
          required: value => !!value || 'Kötelező mező'
        },
        deleteTiming:null,
			}
		},

		computed : {
      pois () {
        if (!this.poiCategory) {
          return []
        }

        return this.$map.pois.filter(x => x.categoryId == this.poiCategory)
      },
			isNew(){
				return this.$route.params.id === 'uj'
			},
      lastSync () {
        if (!this.item.lastStatus) {
          return null;
        }
        return moment(this.item.lastStatus.createdAt * 1000).locale('hu').fromNow()
			},
			statusIconColor()
			{
				return {
					0 : 'error',
					1 : 'success',
					21 : 'orange',
					22 : 'orange',
					31 : 'orange',
					32 : 'orange',
				}[this.item.status]
			},
			statusIcon()
			{
				return {
					0 : 'mdi-alert-circle',
					1 : 'mdi-check-circle',
					21 : 'mdi-refresh-circle',
					22 : 'mdi-refresh-circle',
					31 : 'mdi-power',
					32 : 'mdi-power',
				}[this.item.status]
			},
			statusText()
			{
				return {
					0 : 'Off',
					1 : 'On',
					21 : 'Reboot requested',
					22 : 'Reboot in progress',
					31 : 'Shutdown requested',
					32 : 'Shutdown in progress',
				}[this.item.status]
			},
		},

		methods : {
      confirmDeleteTiming () {
        this.$api.get(`/timing/delete/${this.deleteTiming.id}`)
            .then(() => {
              this.deleteTiming = null;
              this.loadTimings()
          })
      },
      addTime () {
        this.editedTiming.device_id = this.$route.params.id;
        this.$api.post('/timing/save', this.editedTiming)
            .then(() => {
              this.editedTiming = null;
              this.loadTimings()

            })
      },
			save() {

        this.$refs.form.validate();
        if (!this.valid) {
          return false;
        }

				this.$api.post('/device/save', this.item).then((response) => {
					this.$snackbar.show('Saved')
					if (this.isNew) {
						this.$router.push(`/device/${response.data.id}`);
            this.reload();
					}
				})

			},

			doRestart () {
				this.$api.post(`/device/restart/${this.item.id}`).then(() => {
					this.$snackbar.show('Újraindítási parancs elküldve');
          this.reload();
				})
			},

			doShutdown () {
				this.$api.post(`/device/shutdown/${this.item.id}`).then(() => {
					this.$snackbar.show('Leállítási parancs elküldve');
          this.reload();
				})
			},

			loadStatuses () {

				this.headers = [];
					this.cpuUsages = [];
					this.cpuTemperatures = [];
					this.memoryUsages= [];

				this.$api.get(`/device-status/list?device_id=${this.$route.params.id}`).then((resp)=>{
					resp.data.forEach((status) => {
						this.cpuUsages.push(status.cpu_usage);
						this.memoryUsages.push(status.memory_usage);
						this.cpuTemperatures.push(status.cpu_temperature);


						this.headers.push(moment(status.created_at * 1000).locale('hu').format('MMM Do hh:mm'));
					});

					new Chartist.Line(this.$refs.cpuChart, {
						labels: this.headers,
						series: [{ data: this.cpuUsages }]
					})
					new Chartist.Line(this.$refs.memoryChart, {
						labels: this.headers,
						series: [ { data: this.memoryUsages } ]
					})
					new Chartist.Line(this.$refs.tempChart, {
						labels: this.headers,
						series: [ { data: this.cpuTemperatures } ]
					})
				})
			},

      reload () {
        this.$api.get(`/device/get?id=${this.$route.params.id}`).then((resp)=>{
          this.item = resp.data;
        });
      },

      loadHotels () {
        this.$api.get(`/user-group`).then((resp)=>{
          this.groups = resp.data;
        });
      },
      loadPlaylist () {
        this.$api.get(`/playlist`).then((resp)=>{
          this.playlists = resp.data;
        });
      },
      loadTimings () {
        this.$api.get(`/timing?deviceId=${this.$route.params.id}`).then((resp)=>{
          this.timings = resp.data;
        });
      },
		},

		mounted () {
      this.loadHotels();
      this.loadPlaylist();
			if (!this.isNew) {
				this.reload();
				this.loadStatuses()
				this.loadTimings()
			}
		}
	}
</script>