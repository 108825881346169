<template>
  <div class="d-flex align-center justify-end">
    <template v-if="playlist.is_active">
      <small class="pr-2">Aktív</small>
      <v-icon color="success" class="" >mdi-check-circle</v-icon>
    </template>
    <template v-else-if="playlist.timing && playlist.timing > 0">
      <div class="pr-2 text-right">
        <small>Időzítve</small>
        <div class="status-box-date">
          {{ date(timing.start_date) }} - {{ date(timing.end_date) }}
        </div>
      </div>
      <v-icon color="orange">mdi-circle-slice-3</v-icon>
    </template>

    <template v-else>
      <small  class="pr-2">Inaktív</small>
      <v-icon >mdi-stop-circle-outline</v-icon>
    </template>
  </div>

</template>
<script>
export default {
  props:['playlist'],

  data () {
    return {
      timing:null
    }
  },

  methods : {
    date (date) {
      return (new Date(date)).toLocaleDateString('hu-HU', {month:'short', day: 'numeric'})
    }
  },

  mounted () {
    this.$api.get(`/playlist/timing?id=${this.playlist.timing_id}`)
        .then((resp) => {
          this.timing = resp.data;
        })
  }
}
</script>