import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter);


import AppHome from "../page/AppHome.vue";
import DeviceList from "../page/DeviceList.vue";
import DeviceEdit from "../page/DeviceEdit.vue";
import VideoAdmin from "../page/VideoAdmin.vue";
import PlayListAdmin from "../page/PlayListAdmin.vue";
import PlayListEdit from "../page/PlayListEdit.vue";
import UserList from "../page/UserList.vue";
import UserGroupList from "../page/UserGroupList.vue";
import Auth from "../page/Auth.vue";


const router = new VueRouter({
	routes : [
      { path: '/device', component: DeviceList },
      { path: '/device/:id', component: DeviceEdit },
      { path: '/video', component: VideoAdmin },
      { path: '/playlist', component: PlayListAdmin },
      { path: '/playlist/:id', component: PlayListEdit },
      { path: '/user', component: UserList },
      { path: '/user-group', component: UserGroupList },
      { path: '/auth', component: Auth },
      { path: '/', component: AppHome, redirect: { path: '/device' }  },
      { path: '*', component: AppHome, redirect: { path: '/device' }  },
	]
})


export default router