import Vue from 'vue'
import axios from 'axios'
import router from './router'

var auth = new Vue({
  router,

  data: () => ({
    jwt: '',
    loading: true,
    formState:'login',
    user:{}
  }),

  computed : {
    isAdmin () {
      return this.user.permission === 1
    },
    loggedIn () {
      return this.jwt
    },
    isDialogOpen () {
      return !this.loggedIn
    },
    verifyToken () {
      const parts = window.location.href.split('verifyToken=');
      if (parts.length == 2) {
        return parts[1];
      }
      return false;
    }
  },

  methods: {
    login (jwt) {
      this.jwt = jwt;
      window.localStorage.setItem('jwt', jwt);
      axios.defaults.headers.jwt = jwt;
    },
    logout () {
      this.jwt = null;
      window.localStorage.removeItem('jwt');
      axios.defaults.headers.jwt = null;
    }
  },

  created() {
    this.jwt = window.localStorage.getItem('jwt');


    if (this.verifyToken) {
      this.logout()
      this.$router.push('/auth')
      this.formState = 'password-reset'
    }
  },

  watch : {
    isDialogOpen (newValue) {
      if (newValue === false) {
        this.formState = 'login'
      }
    }
  }
})

export default {
  install(Vue) {
    Vue.prototype.$auth = auth
  },
  $auth: auth
};