<template>
  <v-row>
    <v-col cols="8">
      <div class="d-flex justify-space-between mb-4">
        <v-btn small @click="$refs.calendar.prev()"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <div class="text-body-1">{{ calendarMonth }}</div>
        <v-btn small @click="$refs.calendar.next()"><v-icon>mdi-arrow-right</v-icon></v-btn>
      </div>
      <v-calendar type="month" :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  ref="calendar"
                  color="primary"
                  style="min-height: 400px"
                  v-model="calendarDate"
                  :events="allEvents"
                  :event-more="false"
                  @click:date="clicked"
                  @click:event="confirmEventDelete"
      >
      </v-calendar>
    </v-col >
    <v-col cols="4">

      <template v-if="!pickType">
        <v-row>
          <v-col>
            <v-btn  small @click="selectInterval">
              <v-icon small class="mr-2">mdi-calendar-expand-horizontal</v-icon>Intevallum kijelolese
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn small @click="selectDay">
              <v-icon small class="mr-2">mdi-calendar-outline</v-icon>
              Egy nap kijelolese
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col>
            <v-btn  small @click="resetPicking">
              <v-icon small class="mr-2">mdi-cancel</v-icon>Mégse
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="pickType===1">
            <v-alert type="info" dense>Kattints az adott napra</v-alert>
          </v-col>
          <v-col v-if="pickType===2 && pickState === 1">
            <v-alert type="info" dense>Jelöld meg az időszak első napját</v-alert>
          </v-col>
          <v-col v-if="pickType===2 && pickState === 2">
            <v-alert type="info" dense>Most az időszak utolsó napját</v-alert>
          </v-col>
        </v-row>
      </template>

      <v-row>
        <v-col style="max-height: 400px;
    overflow: auto;">
          <div v-if="playlistEvents.length">Időpontok:</div>
          <ul>
            <li v-for="(event, i) in playlistEvents" :key="i">
              <template v-if="event.start != event.end">
                {{ date(event.start) }} - {{ date(event.end) }}
              </template>
              <template v-else>
                {{ date(event.start)  }}
              </template>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="showReservedDayAlert" max-width="400">
      <v-card>
        <v-card-title>Az időztések nem fedhetik egymást</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showReservedDayAlert=false">Rendben</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['playlist'],
  data () {
    return {
      calendarDate:(new Date()),
      otherEvents:[],
      playlistEvents:[],

      pickStart:null,
      pickEnd:null,
      pickState:0,
      pickType:0, // 1-day, 2-interval

      eventDelete:false,
      reservedDays:[],
      showReservedDayAlert:false
    }
  },

  computed : {
    allEvents() {
      return this.otherEvents.concat(this.playlistEvents, this.tmpEvents)
    },
    tmpEvents () {
      if (!this.pickType || !this.pickStart) {
        return  []
      }
      return [
        {
          id: Math.random(),
          name: 'Kijeloles..',
          color: 'green',
          start:this.pickStart,
          end:this.pickEnd
        }
      ]
    },
    calendarMonth() {
      return (new Date(this.calendarDate)).toLocaleDateString('hu-HU', {year:'numeric', month:'long'});
    },
  },

  methods : {
    confirmEventDelete (e) {
      delete this.events[e.eventParsed.index];
    },
    date (time) {
      return (new Date(time)).toLocaleDateString('hu-HU', {month:'long', day: 'numeric'})
    },
    selectInterval()
    {
      this.pickState = 1;
      this.pickType = 2;
    },

    selectDay()
    {
      this.pickState = 1;
      this.pickType = 1;
    },

    resetPicking () {
      this.pickStart = null;
      this.pickEnd = null;
      this.pickType = null;
      this.pickState = null;
    },
    stopPicking () {
      this.playlistEvents.push({
        color: 'primary',
        name: this.playlist.name,
        start: this.pickStart,
        end: this.pickEnd,
      })
      this.getDaysFromInterval(this.pickStart, this.pickEnd).forEach((day) => {
        this.reservedDays.push(day);
      })
      this.resetPicking();
    },
    clicked (e) {
      if (!this.pickType) {
        return;
      }

      var time = (new Date(e.date)).getTime();

      if (this.reservedDays.indexOf(e.date) > -1) {
        this.showReservedDayAlert = true;
        this.resetPicking()
        return;
      }

      if (this.pickType === 1) {
        this.pickStart = time;
        this.pickEnd = time;
        this.stopPicking();
        return;
      }


      if (this.pickState === 1) {
        this.pickStart = time;
        this.pickState = 2;
        return
      }

      if (this.pickState === 2) {
        const dates = [this.pickStart, time]
        this.pickStart = Math.min(...dates);
        this.pickEnd = Math.max(...dates);

        const days = this.getDaysFromInterval(this.pickStart, this.pickEnd);
        for(let i in days) {
          if (this.reservedDays.indexOf(days[i]) > -1) {
            this.showReservedDayAlert = true;
            this.resetPicking();
            return;
          }
        }

        this.stopPicking()
        return
      }

    },

    getDaysFromInterval(start, _end)
    {
      var end = new Date(_end)
      const days = [];
      for (var d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        var date = new Date(d);
        days.push([
          date.getFullYear(),
          (date.getMonth()+1).toString().padStart(2,'0'),
          (date.getDate()).toString().padStart(2,'0')
        ].join('-'));
      }
      return days;
    }
  },

  mounted () {
    this.$api.get(`/playlist/timing/other?playlist_id=${this.playlist.id}`).then((resp) => {

      resp.data.forEach((item) => {

        this.getDaysFromInterval(item.start_date, item.end_date).forEach((day) => {
          this.reservedDays.push(day);
        })

        this.otherEvents.push({
          name: item.playlist.name,
          start: item.start_date,
          end: item.end_date,
          color: 'grey'
        })
      })

    })

    this.playlist.timings.forEach((item) => {
      console.log({
        name: this.playlist.name,
        start: item.start,
        end: item.end,
        color: 'primary'
      })
      this.playlistEvents.push({
        name: this.playlist.name,
        start: item.start * 1000,
        end: item.end * 1000,
        color: 'primary'
      })
    })
  },

  watch : {
    playlistEvents () {
      this.playlist.timings = this.playlistEvents.map(x => {

        return {
          start: x.start / 1000,
          end: x.end / 1000
        }
      })
    }
  }
}
</script>