<template>
	<draggable v-bind="dragOptions" class="dragArea"  :list="subVideos" :group="{ name: 'g1' }" ref="dom">

		<div v-for="el in subVideos">
			<div class="nested-list-item" :class="{'group' : el.isGroup}">

        <v-icon v-if="el.isGroup" color="grey" class="drag-handle">mdi-folder</v-icon>
        <v-icon v-else color="grey" class="drag-handle">{{ mdiReorderHorizontal }}</v-icon>

        <div class="text">
          {{ el.name }}
          <v-spacer></v-spacer>
          <small>{{ duration(el) }}</small>
        </div>
        <v-icon color="grey" @click="removeChild(el)">{{ mdiClose }}</v-icon>

			</div>
			<div class="pl-10 nested-list">
				<nested-draggable v-if="el.isGroup"  :subVideos="el.subVideos" />
			</div>
		</div>

	</draggable>
</template>
<script>
	import draggable from "vuedraggable";
	import { mdiSubdirectoryArrowRight } from '@mdi/js';
	import { mdiFileTree } from '@mdi/js';
	import { mdiPlus } from '@mdi/js';
	import { mdiClose } from '@mdi/js';
	import { mdiReorderHorizontal } from '@mdi/js';
	import { mdiMenu } from '@mdi/js';

	export default {
		name: "nested-draggable",
		props: {
			subVideos: {
				required: true,
				type: Array
			},
			root : {
				type: Boolean,
				default: false
			},
      isGroup : {
				type: Boolean,
				default: false
			}
		},
		components: {
			draggable
		},
		data: () => ({
			childText: '',
			siblingText: '',
			mdiSubdirectoryArrowRight,
			mdiFileTree,
			mdiClose,
			mdiPlus,
			mdiReorderHorizontal,
			mdiMenu,
			currentText: '',
			subEstimates:0
		}),
		computed : {
			dragOptions() {
				return {
					animation: 200,
					disabled: false,
					ghostClass: "ghost",
					handle: ".drag-handle"
				};
			},

		},
		methods : {
      duration(el) {
        if (el.duration) {
          return this.time( el.duration)
        }

        if (el.subVideos && el.subVideos.length) {

          const durations = el.subVideos.map(x => x.duration * 1 );

          const min = Math.min(...durations);
          const max = Math.max(...durations);

          return this.time(min) + ' - ' + this.time(max);
        }

        return this.time(0);
      },

      time (sec) {
        const minute = Math.floor(sec / 60);
        const second = sec % 60;

        return [
          minute.toString().padStart(2, '0'),
          second.toString().padStart(2, '0'),
        ].join(':')
      },
			estimateColor (estimate) {
				if (!estimate) {
					return 'deep-orange lighten-5'
				}
			},
			sumChildEstimate (tasks) {
				var sum = 0;
				for (var i in tasks) {
					sum += (tasks[i].estimate * 1);
				}

				return sum;
			},
			childEstimate (el) {

				console.log('childEstimate')

				this.subEstimates = 0;
				this.sumChildEstimate(el);

				return this.subEstimates;

			},
			addChild(el) {
				el.subVideos.push({
					id: null,
					parentId: el.id,
					name: this.childText,
					subVideos: [],
				})
			},
			removeChild(el) {
				console.log('remove', el);
				const index = this.subVideos.indexOf(el)
				if (index > -1) {
					this.subVideos.splice(index, 1);
				}
			},
			addSubling () {
				this.subVideos.push({
					name: this.siblingText,
					id: [this.parentId, this.subVideos.length].join('.'),
					parentId: this.parentId,
					subVideos: [],
					estimate_hour: 0
				})
				this.siblingText = '';

				this.$nextTick(function () {
					var inputs = this.$refs['dom'].$el.querySelectorAll('input')
					var input = inputs.item(inputs.length - 1);
					input.focus()
				});
			}
		},

	};
</script>
<style scoped>
	.ghost {
		background: rgba(0, 81, 161, 0.31);
		border-radius: 3px;
	}

</style>