<template>
  <v-dialog max-width="410" fixed :value="true" persistent>
    <v-fade-transition hide-on-leave leave-absolute>
      <ForgotPasswordForm v-if="$auth.formState === 'forgot-pass'"></ForgotPasswordForm>
      <LoginForm v-if="$auth.formState === 'login'"></LoginForm>
      <PasswordResetForm v-if="$auth.formState === 'password-reset'"></PasswordResetForm>
    </v-fade-transition>
  </v-dialog>
</template>
<script>

  import LoginForm from "./auth/LoginForm";
  import ForgotPasswordForm from "./auth/ForgotPasswordForm";
  import PasswordResetForm from "./auth/PasswordResetForm";

  export default {
    components : {
      PasswordResetForm,
      ForgotPasswordForm,
      LoginForm
    },

    data() {
      return {
      }
    },

    computed : {
    },

    methods : {
    },

    mounted () {
    }
  }
</script>