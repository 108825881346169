<template>
	<v-card :class="padding">
		<card-header :title="`Password reset`"></card-header>

		<v-card-text v-if="!success">
			<v-form v-model="valid" ref="form">
				<v-text-field @focus="clearError" :rules="[rules.email, badEmail]" v-model="email" id="email" type="email" outlined label="Email cím"></v-text-field>
			</v-form>
		</v-card-text>
		<v-card-text v-else class="text-center">
			<h3>Password reset link sent to your email address</h3>
		</v-card-text>
		<v-card-actions class="pt-8 pb-2" v-if="!success">
			<v-btn @click="$auth.formState='login'" small text class="text-capitalize" exact>Back</v-btn>
			<v-spacer></v-spacer>
			<v-btn :loading="loading" @click="login" class="text-capitalize" color="primary" exact>Request new password</v-btn>
		</v-card-actions>
		<v-card-actions class="pt-8 pb-2" v-else>
			<v-spacer></v-spacer>
		</v-card-actions>
	</v-card>
</template>
<script>

	import CardHeader from "./card-header";


	export default {
		components:{
			CardHeader,
		},

		data: () => ({
			email:'',
			password:'',
			loading:false,
			apiEmailError: false,
			success: false,
			rules: {
				required: value => !!value || 'Kötelező mező',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Hibás email cím'
				}
			},
			valid:false
		}),

		computed : {
			padding () {
				if (window.innerWidth < 900) {
					return 'pa-0'
				}
				return 'pa-6'
			}
		},

		methods: {
			badEmail () {
				return !this.apiEmailError || 'Nem található email cím'
			},
			clearError () {
				if (this.apiEmailError) {
					this.apiEmailError = false;
				}
			},
			login () {
				this.$refs.form.validate();
				if (!this.valid) {
					return false;
				}

				this.loading = true;
				this.$api.post('/user/password/reset', {
					email: this.email,
				}).then(() => {
					this.success = true;
				}).catch((response) => {

					if (response.data.errors.email) {
						this.apiEmailError = true;
					}

					this.loading = false;
					this.$refs.form.validate();
				})
			}
		},
	}
</script>