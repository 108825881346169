<template>
	<v-navigation-drawer v-bind="menuOptions" v-resize="$menu.onResize" :value="$menu.isOpen">
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="title d-flex align-center justify-center">
          <v-img
              style="transition: all .2s"
              :max-width="$menu.isOpen ? 100 : 20"
              :aspect-ratio="1/1" src="../assets/logo.svg"></v-img>
				</v-list-item-title>
				<v-list-item-subtitle>
					{{ userName }}
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>

		<v-divider></v-divider>



		<v-list dense nav>
			<v-list-item link v-for="item, i in menu" :to="item.link" :key="i" >
				<v-list-item-icon>
					<v-icon>{{item.icon}}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{item.text}}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>


    <template v-slot:append>
      <div class="pa-8" v-if="$auth.isAdmin && $menu.isOpen">
        <v-btn :href="`/download?t=${(new Date()).getTime()}`" target="_blank" style="font-size: 12px; text-transform: none;text-align: left">
          <v-icon small class="mr-2">mdi-download</v-icon>
          Download kiosk client
        </v-btn>
      </div>
    </template>



	</v-navigation-drawer>
</template>

<script>

  import {mdiAccount} from '@mdi/js';
  import { mdiYoutube } from '@mdi/js';
  import { mdiDesktopClassic } from '@mdi/js';
  import { mdiFormatListCheckbox } from '@mdi/js';
  import { mdiFolder } from '@mdi/js';

  export default {
    components: {},

    computed: {
      userName() {
        return window.user ? window.user.name : '';
      },
      menuOptions() {
        return {
          app: true,
          stateless: true,
          permanent: true,
          'mini-variant': !this.$menu.isOpen,
        }
      },
      menu() {
        const list = [
          {icon: mdiYoutube, text: 'Videos', link: '/video'},
          {icon: mdiFormatListCheckbox, text: 'Playlists', link: '/playlist'},
          {icon: mdiDesktopClassic, text: 'Devices', link: '/device'},
		    ];
        if (this.$auth.user.permission === 1) {
          list.push({icon: mdiFolder, text: 'User Groups', link: '/user-group'})
          list.push({icon: mdiAccount, text: 'Users', link: '/user'})
        }
        return list;
      }
    },

    data() {
      return {
        advertCount: 0,
        favouriteCount: 0,
        savedSearchCount: 0,
      }
    },

    methods: {
      switchHotel () {
        this.$api.post(`/auth/set-hotel`, {
          'hotelId' : this.$auth.hotel
        })
          .then((response) => {

            this.$auth.login(response.data.jwt)
            window.location.reload();

          })
      }
    },

    mounted() {
    }
  };
</script>