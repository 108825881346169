<template>
  <v-container fluid >

    <v-row class="pa-4">
      <v-btn  @click="save" color="primary">Save</v-btn>
      <v-btn  @click="saveAllConfirmDialog = true"  class="ml-8">Save and set to default on all device</v-btn>
      <v-spacer></v-spacer>
      <v-btn small to="/playlist" >Back to list</v-btn>
    </v-row>


    <v-form  ref="playlistForm" v-model="isValid">

      <v-row>
        <v-col>
          <v-form ref="form" v-model="valid">
            <v-card>
              <v-card-title>Basic datas</v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col>
                    <v-text-field :rules="[rules.required]" label="Playlist name"  outlined v-model="playlist.name"></v-text-field>
                  </v-col>
                  <v-col v-if="$auth.isAdmin">
                    <v-select :items="groups" :rules="[rules.required]" @change="updateGroup" item-text="name" item-value="id" label="User Group" outlined v-model="playlist.user_group_id" ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>

      </v-row>


      <v-row>
        <v-col cols="6">

          <v-card>
            <v-card-title>
              Playlist items
              <v-spacer></v-spacer>
              <small>{{ sumTime }}</small>
            </v-card-title>
            <v-card-text class="pa-4">
              <div class="overflow-auto video-playlist-list right">
                <div v-if="!list.length" class="pa-6">
                  Add Video
                  <v-icon color="grey" small>mdi-arrow-right</v-icon>
                </div>
                <nested-draggable :sub-videos="list"></nested-draggable>
              </div>
            </v-card-text>
          </v-card>


        </v-col>
        <v-col cols="6">



          <v-card>
            <v-card-title>Videos</v-card-title>
            <v-card-text class="pa-4">

              <v-text-field dense placeholder="Filter.." clearable v-model="filterText" outlined></v-text-field>


              <div class="overflow-auto video-playlist-list">
                <v-list dense>


                  <v-list-item @click="addGroup()">
                    <v-list-item-icon>
                      <v-icon>mdi-folder</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Add group</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>


                  <v-list-item @click="add(video)" v-for="(video, i) in filteredVideos" :key="i">
                    <v-list-item-icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="d-flex">
                        {{ video.name}}
                        <v-spacer></v-spacer>

                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <small>{{ time(video.duration) }}</small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="!filteredVideos.length">
                    <v-list-item-content>
                      <v-list-item-subtitle>No result</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>


        </v-col>
      </v-row>



    </v-form>

    <v-dialog v-model="activateDialog" max-width="400">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text v-if="currentPlaylist && currentPlaylist.id != playlist.id">
          Currently active playlist will be inactive: {{ currentPlaylist.name }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="activateDialog=false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="doActivate">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="timingDialog" max-width="900">
      <v-card>
        <v-card-title>Timing Calendar</v-card-title>
        <v-card-text>
          <calendar-picker :playlist="playlist"></calendar-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="timingDialog=false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="timingDialog=false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="saveAllConfirmDialog" max-width="400">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-actions>
          <v-btn @click="saveAllConfirmDialog=false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveToAll">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>

  import draggable from 'vuedraggable';
  import NestedDraggable from "@/components/Nested";
  import CalendarPicker from "@/components/CalendarPicker";

  export default {
    components: {
      CalendarPicker,
      NestedDraggable,
      draggable
    },
    data() {
      return {
        myArray:[],
        groups:[],
        valid:false,
        filterText:'',
        isValid:false,
        headers: [
          {text: 'Name', value: 'name'},
          {text: 'Actions', value: 'actions', sortable:false, width: 120},
        ],
        rules : {
          required: value => !!value || 'required',
        },
        videos: [],
        playlist: {
          name: '',
          video_ids: []
        },
        list:[],
        currentPlaylist:null,
        activateDialog:false,
        timingDialog:false,
        saveAllConfirmDialog:false,


      }
    },

    computed : {

      filteredVideos () {
        let videos = this.videos;

        if (this.$auth.isAdmin) {
          videos = videos.filter(x => x.user_group_id === this.playlist.user_group_id);
        }

        if (this.filterText) {
          videos = videos.filter(x => x.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1)
        }

        return videos;
      },
      sumTime () {
        let minDuration = 0;
        let maxDuration = 0;

        this.list.forEach((item) => {
          if (item.subVideos && item.subVideos.length) {

            const durations = item.subVideos.map(x => x.duration * 1 );

            console.log('durs', durations)

            const min = Math.min(...durations);
            const max = Math.max(...durations);

            minDuration += min;
            maxDuration += max;
          }
          else if (item.duration) {
            minDuration += item.duration;
            maxDuration += item.duration;
          }
        })

        if (minDuration === maxDuration) {
          return this.time(minDuration)
        }

        return this.time(minDuration) + ' - ' + this.time(maxDuration)
      },
      id () {
        return this.$route.params.id;
      },
      isNew(){
        return this.$route.params.id === 'new'
      },
    },

    methods : {

      updateGroup () {
        this.list = [];
      },

      showTiming () {
        this.timingDialog = true;
      },

      doActivate () {
        this.$api.post('/playlist/active', {'id' : this.playlist.id}).then(() => {
          this.loadPlaylist();
          this.activateDialog = false;
        })
      },

      date (time) {
        return (new Date(time * 1000)).toLocaleDateString('hu-HU', {month:'long', day: 'numeric'})
      },

      showActivate () {
        this.activateDialog = true;
      },

      time (sec) {
        const minute = Math.floor(sec / 60);
        const second = sec % 60;

        return [
          minute.toString().padStart(2, '0'),
          second.toString().padStart(2, '0'),
        ].join(':')
      },

      getName (id) {
        return this.getVideoById(id).name;
      },

      getVideoById (id) {
        const video = this.videos.find(x => x.id == id);
        return video ? video : {};
      },

      add (video) {
        video.subVideos = []
        this.list.push(video)
      },

      addGroup () {
        const group = {
          name: 'A-B group',
          subVideos: [],
          isGroup:true
        };
        this.list.push(group)
        return group;
      },


      loadVideos()
      {
        return new Promise(resolve => {
          this.$api.get(`/video`).then((resp)=>{
            this.videos = resp.data
            resolve()
          })
        })
      },

      loadPlaylist()
      {
        return new Promise(resolve => {

          if (this.isNew) {
            resolve();
            return
          }

          this.$api.get(`/playlist/get?id=${this.id}`).then((resp)=>{
            this.playlist = resp.data;
            this.list = [];

            const groups = {};

            this.playlist.videos.forEach((video) => {

              if (video.group_id) {
                let group = null;
                if (!groups[video.group_id]) {
                  groups[video.group_id] = this.addGroup()
                }
                groups[video.group_id].subVideos.push(this.getVideoById(video.id))
              }
              else {
                this.add(this.getVideoById(video.id))
              }
            })
          })

        })
      },

      uid () {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
      },

      saveToAll () {

        this.$refs.form.validate();
        if (!this.valid) {
          return false;
        }

        const ids = []
        this.list.forEach(video => {
          if (video.subVideos && video.subVideos.length) {
            const group = this.uid()
            video.subVideos.forEach(subvideo => {
              ids.push({id: subvideo.id, group: group})
            })
          }
          else {
            ids.push({id: video.id})
          }
        })


        this.$api.post('/playlist/save', {
          id: this.playlist.id,
          name: this.playlist.name,
          videos : ids,
          timings: this.playlist.timings,
          user_group_id: this.playlist.user_group_id
        }).then((resp) => {

          this.$api.post(`/playlist/set-default`, {
            id: resp.data.id
          })
              .then(() => {

                this.saveAllConfirmDialog = false;

                this.$snackbar.show('Saved')


                if (this.isNew) {
                  this.$router.push(`/playlist/${resp.data.id}`)
                }

              })


        })




      },
      save () {

        this.$refs.form.validate();
        if (!this.valid) {
          return false;
        }

        const ids = []
        this.list.forEach(video => {
          if (video.subVideos && video.subVideos.length) {
            const group = this.uid()
            video.subVideos.forEach(subvideo => {
              ids.push({id: subvideo.id, group: group})
            })
          }
          else {
            ids.push({id: video.id})
          }
        })


        this.$api.post('/playlist/save', {
          id: this.playlist.id,
          name: this.playlist.name,
          videos : ids,
          timings: this.playlist.timings,
          user_group_id: this.playlist.user_group_id
        }).then((resp) => {
          this.$snackbar.show('Saved')

          if (this.isNew) {
            this.$router.push(`/playlist/${resp.data.id}`)
          }
        })

      },

      loadGroup()
      {
        this.$api.get(`/user-group`).then((resp)=>{
          this.groups = resp.data
        })
      },

    },

    mounted () {
      this.loadGroup();
      this.loadVideos()
          .then(this.loadPlaylist)
          .then(() => {
            console.log('loaded')
          });



    }
  }
</script>