<template>
  <v-container fluid>
    <v-row>

      <v-col>
        <v-data-table
          :headers="headers"
          :items="videos"
          :items-per-page="15"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Videos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="newEditedVideo">Upload</v-btn>
            </v-toolbar>
          </template>


          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="videoStat=item" icon class="mr-2">
              <v-icon
                small
              >
                mdi-chart-line
              </v-icon>
            </v-btn>
            <v-btn v-if="item.isOwn || $auth.isAdmin" @click="editedVideo=item" icon class="mr-2">
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn v-if="item.isOwn || $auth.isAdmin" icon @click="deleteVideo=item">
              <v-icon
                small
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.duration`]="{ item }">
            {{ time(item.duration) }}
          </template>
          <template v-slot:[`item.view`]="{ item }">
            {{ new Intl.NumberFormat('hu-HU', {maximumSignificantDigits: 3}).format(item.view) }}
          </template>
        </v-data-table>
      </v-col>


    </v-row>



    <v-dialog v-if="deleteVideo" v-model="deleteVideo" width="500">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteVideo=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDeleteVideo">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-if="videoStat" v-model="videoStat" max-width="650">
      <v-card>
        <v-card-title><i>{{ videoStat.name }}</i> views</v-card-title>
        <v-card-text>
          <video-view :id="videoStat.id"></video-view>
        </v-card-text>
        <v-card-actions>
          <v-btn :href="`/video/view/export/${videoStat.id}?t=${(new Date()).getTime()}`" _target="blank" :download="`lejatszasi-adatok-${videoStat.name}.csv`">Export</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="videoStat = null">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>





    <v-dialog v-if="editedVideo" v-model="editedVideo" width="500" persistent>
      <v-card>
        <v-card-title v-if="editedVideo.id">Edit</v-card-title>
        <v-card-title v-else>Upload</v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="form" v-model="editedVideoValid">
            <v-row>
              <v-col>
                <v-text-field :rules="[rules.required]" label="Video name" outlined v-model="editedVideo.name"></v-text-field>
                <v-select v-if="$auth.isAdmin" :rules="[rules.required]" :items="groups" item-value="id" item-text="name"  label="Group" outlined v-model="editedVideo.user_group_id" ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col cols="4">
                  <video-uploader v-on:uploading="uploadInProgress" v-model="editedVideo"></video-uploader>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="editedVideo=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="isUploadInProgress" @click="saveEditedVideo">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




  </v-container>
</template>
<script>

  import VideoUploader from "../components/VideoUploader";
  import VideoView from "@/components/VideoView";
  export default {
    components: {VideoView, VideoUploader},
    data() {
      return {
        groups:[],
        rules : {
          required: value => !!value || 'Required',
        },
        editedVideoValid:false,
        editedPlaylistValid:false,
        videos: [],
        playlists: [],
        deleteVideo: null,
        deletePlayList: null,
        editedVideo:null,
        editedPlayList:null,
        isUploadInProgress:false,
        videoStat:null
      }
    },

    computed : {
      headers() {
        const list = [];
        list.push({text: 'Name', value: 'name'});

        if (this.$auth.isAdmin) {
          list.push({text: 'Group', value: 'group.name'});
        }

        list.push({text: 'Play count', value: 'view'});
        list.push({text: 'Duration', value: 'duration'});
        list.push({text: 'Actions', value: 'actions', sortable:false, width: 160});

        return list;
      },
    },

    methods : {
      time (sec) {
        const minute = Math.floor(sec / 60);
        const second = sec % 60;

        return [
          minute.toString().padStart(2, '0'),
          second.toString().padStart(2, '0'),
        ].join(':')
      },
      uploadInProgress (value) {
        this.isUploadInProgress = value;
      },
      showDeleteItem (item) {
        this.deleteItem = item;
        this.showDelete = true;
      },
      confirmDeleteVideo() {
        this.$api.get(`/video/delete/${this.deleteVideo.id}`).then(()=>{
          this.loadVideos();
          this.deleteVideo = null;
        })
      },
      confirmDeletePlayList() {
        this.$api.get(`/playlist/delete/${this.deletePlayList.id}`).then(()=>{
          this.loadPlayLists();
          this.deletePlayList = null;
        })
      },

      newEditedPlayList()
      {
        this.editedPlayList = {
          name:'',
          videoIds:[]
        }
      },
      saveEditedPlayList()
      {
        this.$refs.playlistForm.validate();
        if (!this.editedPlaylistValid) {
          return false;
        }

        this.$api.post('/playlist/save', this.editedPlayList).then(() => {
          this.$snackbar.show('Saved');
          this.editedPlayList = null;
          this.loadPlayLists();
        })
      },

      newEditedVideo()
      {
        this.editedVideo = {
          name:'',
          key:null,
          duration:0
        }
      },
      saveEditedVideo()
      {
        this.$refs.form.validate();
        if (!this.editedVideoValid) {
          return false;
        }


        this.$api.post('/video/save', this.editedVideo).then(() => {
          this.$snackbar.show('Saved');
          this.editedVideo = null;
          this.loadVideos();
        })
      },

      loadVideos()
      {
        this.$api.get(`/video`).then((resp)=>{
          this.videos = resp.data
        })
      },
      loadGroup()
      {
        this.$api.get(`/user-group`).then((resp)=>{
          this.groups = resp.data
        })
      },
      loadPlayLists()
      {
        this.$api.get(`/playlist`).then((resp)=>{
          this.playlists = resp.data
        })
      }
    },

    mounted () {
      this.loadVideos();
      this.loadPlayLists();
      this.loadGroup();
    }
  }
</script>