<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="playlists"
          :items-per-page="15"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Playlists</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small color="primary" to="/playlist/new">Create new</v-btn>
            </v-toolbar>
          </template>



          <template v-slot:[`item.actions`]="{ item }">
            <v-btn v-if="item.isOwn || $auth.isAdmin" icon class="mr-2" :to="`/playlist/${item.id}`">
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn v-if="item.isOwn || $auth.isAdmin" icon @click="deletePlayList=item">
              <v-icon
                small
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.timing`]="{ item }">
            <status-box :playlist="item"></status-box>
          </template>

          <template v-slot:header.timing="{ header }" >
            <span style="display: inline-block;
    text-align: right;
    width: 134px;">
              {{ header.text }}
            </span>
          </template>

        </v-data-table>
      </v-col>



    </v-row>



    <v-dialog v-if="deletePlayList" v-model="deletePlayList" width="500">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deletePlayList=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDeletePlayList">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



  </v-container>
</template>
<script>

  import draggable from 'vuedraggable';
  import StatusBox from "@/components/StatusBox";

  export default {
    components: {
      StatusBox,
      draggable
    },
    data() {
      return {
        myArray:[],
        rules : {
          required: value => !!value || 'required',
        },
        editedVideoValid:false,
        editedPlaylistValid:false,
        videos: [],
        playlists: [],
        deleteVideo: null,
        deletePlayList: null,
        editedVideo:null,
        editedPlayList:null
      }
    },

    computed : {
      headers () {
        const list = [];
        list.push({text: 'ID', value: 'id', width: 70});
        list.push({text: 'Name', value: 'name'});


        if (this.$auth.isAdmin) {
          list.push({text: 'Group', value: 'group.name'});
        }

        list.push({text: 'Actions', value: 'actions', sortable:false, width: 120});

        return list;
      }
    },

    methods : {
      showEditPlaylist(item) {
        this.editedPlayList=item;
        this.editedPlayList.video_ids = this.editedPlayList.video_ids.filter((x) => {
          return this.videos.find(a => a.id == x)
        })
      },
      getName (id) {
        const video = this.videos.find(x => x.id == id);
        return video ? video.name : null;
      },
      showDeleteItem (item) {
        this.deleteItem = item;
        this.showDelete = true;
      },
      confirmDeleteVideo() {
        this.$api.get(`/video/delete/${this.deleteVideo.id}`).then(()=>{
          this.loadVideos();
          this.deleteVideo = null;
        })
      },
      confirmDeletePlayList() {
        this.$api.get(`/playlist/delete/${this.deletePlayList.id}`).then(()=>{
          this.loadPlayLists();
          this.deletePlayList = null;
        })
      },

      newEditedPlayList()
      {
        this.editedPlayList = {
          name:'',
          video_ids:[]
        }
      },
      saveEditedPlayList()
      {
        this.$refs.playlistForm.validate();
        if (!this.editedPlaylistValid) {
          return false;
        }

        this.$api.post('/playlist/save', this.editedPlayList).then(() => {
          this.$snackbar.show('Saved');
          this.editedPlayList = null;
          this.loadPlayLists();
        })
      },

      newEditedVideo()
      {
        this.editedVideo = {
          name:'',
          imageId:null
        }
      },
      saveEditedVideo()
      {
        this.$refs.form.validate();
        if (!this.editedVideoValid) {
          return false;
        }


        this.$api.post('/video/save', this.editedVideo).then(() => {
          this.$snackbar.show('Saved');
          this.editedVideo = null;
          this.loadVideos();
        })
      },

      loadVideos()
      {
        this.$api.get(`/video`).then((resp)=>{
          this.videos = resp.data
        })
      },
      loadPlayLists()
      {
        this.$api.get(`/playlist`).then((resp)=>{
          this.playlists = resp.data
        })
      }
    },

    mounted () {
      this.loadVideos();
      this.loadPlayLists();
    }
  }
</script>