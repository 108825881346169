<template>
   <div class="video-view-list">
     <div class="view-list-item" v-for="item in items">
       <div class="date">{{ date(item.date) }}</div>
       <div class="count">
         <span class="badge">
           {{ new Intl.NumberFormat('hu-HU', {maximumSignificantDigits: 3}).format(item.count) }}
         </span>
       </div>
       <div class="line">
         <v-sheet class="sheet" :style="`width:${width(item.count)}%`" color="primary"></v-sheet>
       </div>
     </div>
   </div>
</template>

<script>
import Chartist from 'chartist'
import moment from 'moment'

export default {
  props: ['id'],
  data () {
    return {
      items: [],
      max:0,
    }
  },
  methods : {
    width (count) {
      return 100 / this.max * count;
    },
    date (date) {
      return moment(date).locale('hu').format('YYYY MMM Do');
    },
  },
  mounted () {
    this.$api.get(`/video/view?id=${this.id}`).then((resp) => {
      this.items = resp.data;

      this.items.forEach((item) => {
        if (item.count > this.max) {
          this.max = item.count;
        }
      })
    })
  }
}
</script>