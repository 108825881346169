<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>User Groups</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small v-if="$auth.isAdmin" @click="newItem" color="primary">Create new</v-btn>
            </v-toolbar>
          </template>



          <template v-slot:[`item.actions`]="{ item }">
            <v-btn  @click="createItem=item" icon>
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn v-if="$auth.isAdmin" @click="deleteItem=item" icon>
              <v-icon
                small
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="createItem" v-model="createItem" width="500">
      <v-card>
        <v-card-title v-if="createItem.id">Edit group</v-card-title>
        <v-card-title v-else>Create new</v-card-title>
        <v-card-text>
          <v-text-field :rules="[rules.required]" outlined v-model="createItem.name" label="name"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="createItem=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveItem" :disabled="saveInProgress" :loading="saveInProgress">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="deleteItem" v-model="deleteItem" width="500">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteItem=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDelete">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

  export default {
    data() {
      return {
        saveInProgress:false,
        headers: [
          {text: 'Name', value: 'name'},
          {text: 'Actions', value: 'actions', sortable:false, width: 160},
        ],
        items: [],
        devices: [],
        deleteItem: null,
        createItem: null,
        rules: {
          required: value => !!value || 'required',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'invalid email address'
          }
        },
      }
    },

    methods : {
      confirmDelete() {
        this.$api.get(`/user-group/delete/${this.deleteItem.id}`).then(()=>{
          this.deleteItem = null;
          this.loadItems();
        })
      },
      saveItem() {
        this.saveInProgress = true;
        this.$api.post(`/user-group/save`, this.createItem).then(()=>{
          this.createItem = null;
          this.loadItems();
          this.saveInProgress = false;
        })
      },
      newItem () {
        this.createItem = {}
      },
      loadDevices() {
        this.$api.get(`/device/list`).then((resp)=>{
          this.devices = resp.data
        })
      },
      loadItems() {
        this.$api.get(`/user-group`).then((resp)=>{
          this.items = resp.data
        })
      }
    },

    mounted () {
      this.loadItems()
      this.loadDevices()
    }
  }
</script>