<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Users</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small @click="newItem" color="primary">Create new</v-btn>
            </v-toolbar>
          </template>



          <template v-slot:[`item.permission`]="{ item }">
            <span v-if="item.permission === 1">Super Admin</span>
            <span v-if="item.permission === 2">User</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn  @click="createItem=item" icon>
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn  @click="deleteItem=item" icon>
              <v-icon
                small
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="createItem" v-model="createItem" width="500">
      <v-card>
        <v-card-title v-if="createItem.id">Edit user</v-card-title>
        <v-card-title v-else>Create new</v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-text-field :disabled="!!createItem.id" :rules="[rules.email]" outlined v-model="createItem.email" label="email address"></v-text-field>
            <v-select :rules="[rules.required]" outlined v-model="createItem.permission" label="Permission" :items="perms"></v-select>
            <v-select v-if="createItem.permission === 2" :rules="[rules.required]" :items="hotels" item-value="id" item-text="name"  label="Group" outlined v-model="createItem.user_group_id" ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="createItem=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveItem" :disabled="saveInProgress" :loading="saveInProgress">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="deleteItem" v-model="deleteItem" width="500">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteItem=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDelete">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

  export default {
    data() {
      return {
        valid:false,
        hotelFilter: '',
        saveInProgress:false,
        perms: [
          {text: 'Super Admin', value: 1},
          {text: 'User', value: 2},
        ],
        headers: [
          {text: 'Email', value: 'email'},
          {text: 'Group', value: 'group.name'},
          {text: 'Permission', value: 'permission'},
          {text: 'Actions', value: 'actions', sortable:false, width: 120},
        ],
        items: [],
        hotels: [],
        deleteItem: null,
        createItem: null,
        rules: {
          required: value => !!value || 'required',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'invalid email address'
          }
        },
      }
    },

    computed : {
      filteredHotels () {
        return this.hotels.filter(x => { return x.name.indexOf(this.hotelFilter) > -1})
      }
    },

    methods : {
      confirmDelete() {
        this.$api.get(`/user/delete/${this.deleteItem.id}`).then(()=>{
          this.deleteItem = null;
          this.loadItems();
        })
      },
      saveItem() {
        this.$refs.form.validate()
        if (!this.valid) {
          return false;
        }
        this.saveInProgress = true;
        this.$api.post(`/user/save`, this.createItem).then(()=>{
          this.createItem = null;
          this.loadItems();
          this.saveInProgress = false;
        })
      },
      newItem () {
        this.createItem = {
          email:''
        }
      },
      loadItems() {
        this.$api.get(`/user`).then((resp)=>{
          this.items = resp.data
        })
      },
      loadHotels() {
        this.$api.get(`/user-group`).then((resp)=>{
          this.hotels = resp.data
        })
      }
    },

    mounted () {
      this.loadItems()
      this.loadHotels()
    }
  }
</script>