<template>
  <v-responsive class="uploader-placeholder video" :aspect-ratio="9/16">
    <video v-show="videoLoaded" ref="video" :src="src" muted autoplay></video>

    <div v-if="loading" class="preload-box fill-height justify-center align-center d-flex flex-column">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <div v-if="loadingStep === 1" class="pt-3">
        Start uploading...
      </div>
      <div class="progress" v-else-if="loadingStep === 2">
        <div class="percent">{{ percent }} %</div>
        <div class="bytes">{{ showbyte(uploadedSize) }} / {{ showbyte(totalSize) }}</div>
      </div>
      <div v-else-if="loadingStep === 3" class="pt-3">
        Loading video...
      </div>
    </div>
    <template v-else-if="videoLoaded">
      <v-btn @click="deleteVideo" fab elevation="2" class="delete-button" color="error">
        <v-icon >mdi-close</v-icon>
      </v-btn>
    </template>
    <div v-else-if="hasError" class="upload-error">
      <div class="title">Wrong format!</div>
      <div class="text">
        Allowed format <b>mp4</b> or <b>webm</b> and max <b>500 Mb</b>
      </div>
      <v-btn @click="clearError" color="mt-6" small>Browse another video file</v-btn>

    </div>
    <label v-else class="fill-height">

      <input ref="files" accept="video/mp4, video/webm, video/avi" @change="fileChanged" type="file">
      <v-icon>mdi-upload</v-icon>
      <small>browse</small>
      <small>1920x1080 - mp4, webm</small>

      <div>
        <v-input :rules="[rules.required]" :value="valueCopy"></v-input>
      </div>

    </label>
  </v-responsive>
</template>

<script>

  export default {

    components : {
    },

    props: [
      'value',
      'ratio',
    ],
    data() {
      return {
        rules : {
          required: value => !!value || 'Browse a video',
        },
        src: null,
        loading:false,
        hasError:false,
        totalSize:0,
        uploadedSize:0,
        percent:0,
        video: {
          duration:0,
          key: null
        }
      }
    },

    computed : {
      valueCopy () {
        return !!this.value.key;
      },
      videoLoaded () {
        return !this.loading && this.src
      }
    },

    methods : {
      showbyte (byte) {
        const mb = byte / 1024 / 1024;

        return mb.toFixed(2) + ' MB'
      },
      deleteVideo (){
        this.src = null;
        this.value.key = null;
      },
      uploadProgress (progressEvent) {
         this.totalSize = progressEvent.total
         this.uploadedSize = progressEvent.loaded
         this.percent = (this.uploadedSize / this.totalSize * 100).toFixed(1)
      },
      clearError () {
        this.uploading = false;
        this.hasError = false;
      },

      handleError () {
        this.uploading = false;
        this.hasError = true;
      },

      getDuration (file) {
        return new Promise(resolve => {
          const video = document.createElement('video');
          video.ondurationchange = function () {
            resolve({
              file,
              duration: video.duration
            })
          }
          video.src = URL.createObjectURL(file)
        })
      },

      getUploadDetails (args) {
        return new Promise((resolve, reject) => {

          this.$api.post('/video/backblaze', {
            filetype: args.file.type,
          })
            .then((resp) => {
              const data = resp.data;
              const extension = args.file.name.split('.').pop();
              const filename = data.filename + '.' + extension;

              resolve({
                file: args.file,
                duration: args.duration,
                uploadUrl: data.uploadUrl,
                authToken: data.authorizationToken,
                filename
              })
            })
              .catch(reject)
        })
      },

      upload(args) {
        return new Promise((resolve, reject) => {

          this.loadingStep = 2;
          this.$forceUpdate();

          console.log('upload', this)


          this.$api.post(args.uploadUrl, args.file, {
            onUploadProgress: this.uploadProgress,
            headers: {
              Authorization: args.authToken,
              'X-Bz-File-Name' : args.filename,
              'X-Bz-Content-Sha1' : 'do_not_verify',
              'Content-Type' : args.file.type
            }
          }).then((resp) => {
            resolve({
              key: args.filename,
              duration: args.duration
            })
          })
          .catch(reject)

        })
      },

      saveVideo (args) {
        return new Promise((resolve, reject) => {

          this.$api.post('/video/save', {
            key: args.key,
            duration: args.duration
          }).then(resolve)
            .catch(reject)

        })
      },

      fileChanged () {
        this.loading = true;
        this.loadingStep = 1;
        this.$forceUpdate();
        this.$emit('uploading', true);
        const file = this.$refs.files.files[0];

        this.getDuration(file)
            .then(this.getUploadDetails)
            .then(this.upload)
            .then((resp) => {

              this.value.duration = resp.duration;
              this.value.key = resp.key;

              this.$emit('uploading', false);


              console.log('upload done', resp)
            })
            .catch((resp) => {
              console.log('upload err', resp)
            })




      },

      loadVideo() {
        this.loading = true;
        this.loadingStep = 3;
        this.$forceUpdate();
        this.$api.get(`/video/url?key=${this.value.key}`).then((resp) => {
          this.src = resp.data;
      })
    }
    },

    mounted() {

      if (this.value.key) {
        this.loadVideo()
      }

      this.$refs.video.addEventListener('canplay', () => {
        this.loading = false;
      });
    },

    watch : {
      'value.key' (newValue) {
        if (newValue) {
          this.loadVideo()
        }
        else {
          this.src = null;
        }
      }
    }
  }

</script>