<template>
	<v-card :class="padding">
		<card-header :title="`Login`"></card-header>

			<v-card-text>
				<v-form  v-model="valid" ref="form">
					<v-text-field @focus="clearError" :rules="[rules.email, badEmail]" v-model="email" id="email" type="email" outlined label="Email"></v-text-field>
					<v-text-field @focus="clearError" @keyup.enter="login" :rules="[rules.required, badPassword]" v-model="password" id="password" type="password" outlined label="Password"></v-text-field>
					<v-btn @click="$auth.formState='forgot-pass'" x-small text class="text-lowercase">Forgot my password</v-btn>
				</v-form>
			</v-card-text>
			<v-card-actions class="pt-8 pb-2">
				<v-spacer></v-spacer>
				<v-btn :loading="loading" @click="login" class="text-capitalize" color="primary">Login</v-btn>
			</v-card-actions>


	</v-card>
</template>
<script>

	import CardHeader from "./card-header";

	export default {

		components: {
			CardHeader,
		},

		data: () => ({
			email:'',
			password:'',
			loading:false,
			apiEmailError: false,
			apiPasswordError: false,
			rules: {
				required: value => !!value || 'Required',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Not valid email address'
				}
			},
			valid:false
		}),

		computed : {
			isMobile () {
				return window.innerWidth < 600
			},
			padding () {
				if (window.innerWidth < 900) {
					return 'pa-0'
				}
				return 'pa-6'
			}
		},

		methods: {
			badPassword () {
				return !this.apiPasswordError || 'Wrong password'
			},
			badEmail () {
				return !this.apiEmailError || 'Email not found'
			},
			clearError () {
				if (this.apiEmailError) {
					this.apiEmailError = false;
				}
				if (this.apiPasswordError) {
					this.apiPasswordError = false;
					this.password = '';
				}
			},
			login () {
				this.$refs.form.validate();
				if (!this.valid) {
					return false;
				}

				this.loading = true;
				this.$api.post('/auth/login', {
					email: this.email,
					password: this.password,
				}).then((response) => {
					this.$auth.login(response.data.jwt)

          this.$router.go()

					setTimeout(() => {
						this.loading = false;
					}, 3000)
				}).catch((error) => {

					if (error.response.data.errors.password) {
						this.apiPasswordError = true;
					}
					if (error.response.data.errors.email) {
						this.apiEmailError = true;
					}

					this.loading = false;
					this.$refs.form.validate();
				})
			}
		},
	}
</script>