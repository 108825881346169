<template>
  <v-dialog v-model="show" width="400" persistent>
    <v-form v-model="isValid" v-if="value" ref="form">
      <v-card>
        <v-card-title>Edit Timing</v-card-title>
        <v-card-text>

          <v-subheader>Playlist</v-subheader>

          <v-select :items="playlists" :rules="[rules.required]" item-text="name" item-value="id" outlined v-model="value.playlist_id" ></v-select>

          <v-subheader>From</v-subheader>
          <v-row>
            <v-col>
              <v-text-field dense  :rules="[rules.required]"  outlined type="date" v-model="value.from_date"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field dense :rules="[rules.required]"  outlined type="time" v-model="value.from_time"></v-text-field>
            </v-col>
          </v-row>

          <v-subheader>To</v-subheader>
          <v-row>
            <v-col>
              <v-text-field dense :rules="[rules.required]" outlined type="date" v-model="value.to_date"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field dense :rules="[rules.required]" outlined type="time" v-model="value.to_time"></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancel">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveItem" :loading="saveLoading" :disabled="saveLoading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>
<script>
export default {
  props: ['value', 'playlists'],

  data () {
    return {
      show:false,
      isValid:false,
      saveLoading:false,
      rules : {
        required: value => !!value || 'Required field',
      },
    }
  },

  computed : {
    timing () {
      return {}
    }
  },

  mounted () {
    console.log('mounted', this.value)
  },

  methods : {
    saveItem () {
      this.saveLoading = true;
      this.$refs.form.validate();

      if (!this.isValid) {
        this.saveLoading = false;
        return
      }
      this.$emit('saved')
    },
    cancel () {
      this.$emit('input', null)
    }
  },

  watch : {
    value () {
      console.log('value change', this.value)
      this.show = this.value;
      this.saveLoading = false;
    }
  }
}
</script>